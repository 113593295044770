import { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { isDefaultHeartsPresent } from 'helpers/HeartUtils';
import { getPDPTrackingPropsFormatted, priceToFloat } from 'helpers/ProductUtils';
import { SmallLoader } from 'components/Loader';
import { getLists, getListsForItemId, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import NewCollectionModal from 'components/account/Collections/NewCollectionModal';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useEvent from 'hooks/useEvent';

import css from 'styles/components/account/socialCollectionsWidget.scss';

const OPEN = 'OPEN';
const SHOW_MORE = 'SHOW_MORE';
const SAVE_COLLECTION = 'SAVE_COLLECTION';
const ON_OPEN_MENU = 'ON_OPEN_MENU';
const SHOW_NEW_COLLECTION_MODAL = 'SHOW_NEW_COLLECTION_MODAL';
const IS_LOADING = 'IS_LOADING';

const initialState = {
  showMore: false,
  showNewCollectionModal: false,
  isLoading: false,
  hasOpened: false
};

const reducer = (state, action) => {
  const { type, showMore, showNewCollectionModal, isLoading } = action;
  switch (type) {
    case OPEN:
      return { ...state, hasOpened: true };
    case SHOW_MORE:
      return { ...state, showMore };
    case SAVE_COLLECTION:
      return { ...state, showMore: true, showNewCollectionModal: false };
    case SHOW_NEW_COLLECTION_MODAL:
      return { ...state, showNewCollectionModal };
    case IS_LOADING:
      return { ...state, isLoading };
    case ON_OPEN_MENU:
      return { ...state, showMore: true, isLoading: true };
    default:
      return initialState;
  }
};

const CreateNewCollection = ({ showMore, toggleNewCollectionModal, testId, canAddNewCollection }) =>
  canAddNewCollection && (
    <button
      type="button"
      className={css.addNew}
      aria-expanded={showMore}
      onClick={toggleNewCollectionModal(true)}
      data-test-id={testId('addToNewCollection')}
    >
      Create New
    </button>
  );

export const SocialCollectionsWidget = ({
  canAddNewCollection = false,
  shouldAddImmediately = false,
  heartProduct,
  unHeartProduct,
  isCustomer,
  getStyleId,
  getLists,
  getListsForItemId,
  toggleHeartingLoginModal,
  hearts,
  colorId,
  productId,
  price,
  missingDimension,
  sourcePage,
  productImages,
  nonLoggedInCallback = () => null
}) => {
  const firstList = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { showMore, showNewCollectionModal, isLoading, hasOpened } = state;

  const {
    marketplace: {
      account: { simpleCollections }
    }
  } = useMartyContext();

  const itemId = getStyleId();
  const priceAsFloat = typeof price === 'string' ? priceToFloat(price) : price;

  const { lists, itemIdLists } = hearts;

  const { testId } = useMartyContext();

  const onGetListsForItemId = listId => {
    getListsForItemId({ itemId }).then(() => {
      dispatch({ type: IS_LOADING, isLoading: false });
      if (listId) {
        document.getElementById(listId)?.focus();
      } else {
        firstList.current?.focus();
      }
    });
  };

  // Handler for escape keyboard key
  useEvent(document, 'keyup', keyboard => {
    if (showMore) {
      const key = keyboard.key || keyboard.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        onCloseMenu();
      }
    }
  });

  const getHeartInfo = (listId = 'h.') => ({
    itemId,
    listId,
    colorId,
    productId,
    price: priceAsFloat,
    missingDimension,
    sourcePage
  });

  const getListDetails = useCallback(() => {
    if (!lists || !isDefaultHeartsPresent(lists)) {
      getLists().then(onGetListsForItemId);
    } else {
      onGetListsForItemId();
    }
  }, [lists, itemId]); // eslint-disable-line react-hooks/exhaustive-deps

  // checks if the user has hearted this product before on load
  useEffect(() => {
    if (isCustomer) {
      getListDetails();
    }
  }, [getListDetails, isCustomer]);

  const onCloseMenu = () => {
    dispatch({ type: SHOW_MORE, showMore: false });
  };

  const onOpenMenu = () => {
    if (!isCustomer) {
      nonLoggedInCallback();
      return toggleHeartingLoginModal(true);
    }

    if (!hasOpened) {
      dispatch({ type: OPEN });
    }

    if (shouldAddImmediately && !hasOpened) {
      // heart product to default list immediately when opened
      heartProduct(getHeartInfo(), getListDetails);
    } else {
      // otherwise, just get an updated list of collections
      getListDetails();
    }

    dispatch({ type: ON_OPEN_MENU });
  };

  const onCollectionChange = e => {
    const { checked, id } = e.currentTarget;
    dispatch({ type: IS_LOADING, isLoading: true });

    // `checked` comes back as the state it "will be" once its been clicked
    const heartType = checked ? heartProduct : unHeartProduct;
    heartType(getHeartInfo(id), () => {
      onGetListsForItemId(id);
    });
  };

  const onSaveNewCollection = response => {
    const { listId } = response;
    dispatch({ type: SAVE_COLLECTION });

    heartProduct(getHeartInfo(listId), () => {
      getLists().then(onGetListsForItemId);
    });
  };

  const toggleNewCollectionModal = useCallback(
    showNewCollectionModal => e => {
      if (!e.target.closest(`[data-collection="${itemId}"]`)) {
        dispatch({ type: SHOW_MORE, showMore: false });
      }
      dispatch({ type: SHOW_NEW_COLLECTION_MODAL, showNewCollectionModal });
    },
    []
  );

  // suppress the rendering of this component if marketplace is Simple Collections
  if (simpleCollections) {
    return null;
  }

  // Legacy settings
  const basicStyle = !(itemIdLists?.length > 0);
  const saveLabel = `${basicStyle ? 'Save' : 'Saved'} to Favorites`;

  let firstMatchingList; // returns first list in collections

  return (
    <>
      <div data-collection={itemId} className={cn(css.container, { [css.showMore]: showMore })}>
        <button
          type="button"
          aria-expanded={showMore}
          data-test-id={testId('addToFavorites')}
          onClick={showMore ? onCloseMenu : onOpenMenu}
          className={cn(css.addToButton, { [css.basic]: basicStyle }, { [css.isOpenZapr]: showMore })}
          {...getPDPTrackingPropsFormatted('Favorites', 'Button-Click')}
        >
          <span className={css.addToCollectionButtonLabel}>{saveLabel}</span>
        </button>
        {showMore && (
          <div className={css.lists}>
            {lists && itemIdLists && !isLoading ? (
              lists.map(({ listId, name }, index) => {
                const matchingList = itemIdLists.find(itemIdList => listId === itemIdList.listId);

                if (!firstMatchingList) {
                  firstMatchingList = matchingList;
                }

                let firstListRef;

                switch (true) {
                  case firstMatchingList?.listId === listId: // if you have matching list, focus there first
                  case index === 0: // if no matching list, focus first available
                    firstListRef = firstList;
                    break;
                  default:
                    firstListRef = null;
                }

                return (
                  <Fragment key={listId}>
                    <input id={`${listId}`} ref={firstListRef} onChange={onCollectionChange} defaultChecked={matchingList} type="checkbox" />
                    <label className={css.collectionNameLabel} htmlFor={`${listId}`} data-test-id={testId(`collection_${name}`)}>
                      {name}
                    </label>
                  </Fragment>
                );
              })
            ) : (
              <SmallLoader />
            )}
            <CreateNewCollection
              showMore={showMore}
              toggleNewCollectionModal={toggleNewCollectionModal}
              testId={testId}
              canAddNewCollection={canAddNewCollection}
            />
          </div>
        )}
      </div>
      {canAddNewCollection && (
        <NewCollectionModal
          productImages={productImages}
          showNewCollectionModal={showNewCollectionModal}
          onCancelModal={toggleNewCollectionModal(false)}
          onDoneModal={onSaveNewCollection}
        />
      )}
    </>
  );
};

export const mapStateToProps = state => {
  const { cookies, hearts: hearts } = state;

  return {
    isCustomer: !!cookies['x-main'],
    hearts
  };
};

const ConnectedSocialCollectionsWidget = connect(mapStateToProps, {
  getLists,
  getListsForItemId,
  heartProduct,
  unHeartProduct,
  toggleHeartingLoginModal
})(SocialCollectionsWidget);

export default withErrorBoundary('SocialCollectionsWidget', ConnectedSocialCollectionsWidget);
